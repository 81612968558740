<template>
  <v-layout style="background-color: #fff" row>
    <v-layout
      v-if="smUp"
      :style="
        bMiniVariant
          ? 'margin-left: 0px;max-width: 5%;'
          : 'margin-left: 0px;max-width: 20%;'
      "
      column
    >
      <!-- Start admin side menu -->
      <core-drawer> </core-drawer>
      <!-- End admin side menu -->
    </v-layout>
    <v-layout
      :style="
      (smUp)?(
        bMiniVariant
          ? 'background-color:#FFF; max-width: 95% !important'
          : 'background-color:#FFF;  max-width: 80% !important') : ''
      "
      :class="!smUp? 'content-full' : 'content-with-menu'"
      column
    >
      <!-- Start admin header -->
      <core-header> </core-header>
      <!-- End admin header -->
      <!-- Start router view for subroutes of adminn -->
      <transition name="slide-fade">
        <router-view
          :texts="texts"
          :mdUp="mdUp"
          :smUp="smUp"
          :bMini="bMiniVariant"
        />
      </transition>
      <!-- Ed router view for subroutes of admin -->
      <core-delete> </core-delete>
    </v-layout>
    <!-- Start delete dialog -->
    <!-- End delete dialog -->
  </v-layout>
  <!-- <div
    class="global-container-flex">
    <div
  :class="bMiniVariant ? 'global-container-menu':'global-container-menu-close'">
   <core-drawer-new> </core-drawer-new>
</div>
    <div
    :class="bMiniVariant ? 'global-container-content' : 'global-container-content-full-width'">
    <core-header> </core-header>
    <div 
    class="global-container-router-view"
    :style="{ height: screenHeight + 'px' }">
      <router-view
          :texts="texts"
          :mdUp="mdUp"
          :smUp="smUp"
          :bMini="bMiniVariant"
        />
    </div>
    </div>
     <core-delete> </core-delete>
  </div> -->
</template>
<script>
export default {
  data() {
    return {
      isMobile: false,
      TabletAndMobile: 0,
      screenHeight: 0
    };
  },
  props: {
    texts: Object,
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      // if (window.innerWidth < 1246) {
        this.TabletAndMobile = window.innerWidth < 1246
      // }
       this.screenHeight = window.innerHeight - 43;
      this.isMobile = window.innerWidth < 600;
    },
  },
  computed: {
    adminTexts() {
      return this.texts.spanish.admin;
    },
    // return mini variant for adustments
    bMiniVariant() {
      return this.$store.state.bMiniVariant;
    },
    // medium breakpoints
    mdUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    // small breakpoint
    smUp() {
      // return this.$vuetify.breakpoint.smAndUp;
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  components: {
    // import admin navigation drawer
    CoreHeader: () => import("../components/core/Header"),
    // import admin navigation drawer
    CoreDrawer: () => import("../components/core/SideMenu"),
     // import admin navigation drawer NEW  
    CoreDrawerNew: () => import("../components/core/SideMenuNew"),
    // import delete dialog
    CoreDelete: () => import("../components/core/Delete"),
  },
};
</script>
<style>

.content-full{
  /* background-color: tomato !important; */
  max-width: 100% !important;
  width: 100% !important;
  /* width: 100% !important; */
}
.content-with-menu{

}
.noDataMessage {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 25px;
  color: #000000;
  margin: 20px;
}
.SectionTitle {
  margin-top: 2%;
  font-family: "Poppins", sans-serif;
  font-weight: SemiBold;
  font-weight: 600;
  font-size: 36px;
  color: #000000;
}
.MiniPadding {
  padding-left: 40px !important;
  padding-top: 25px;
}
.MiniPaddingWithTab {
  padding-left: 10px !important;
  padding-top: 25px;
}
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.viewLayoutWrapper {
  padding-left: 30px;
  max-width: 96%;
}

viewLayoutWrapperWithTab{
  padding-left: 30px;
  max-width: 96%;
}

.min-w-200{
  min-width: 200px !important;
}

.w-80{
  min-width: 80px;
  max-width: 80px;
}
.w-100{
  min-width: 100px;
  max-width: 100px;
}
.w-150{
  min-width: 150px;
  max-width: 150px;
}
.w-180{
  min-width: 180px;
  max-width: 180px;
}
.w-200{
  min-width: 200px;
  max-width: 200px;
}
.w-250{
  min-width: 250px;
  max-width: 250px;
}

.icon-delete-table-global{
  font-size: 25px !important;
  color:#FF5757 !important
}
.icon-eye-table-global{
  font-size: 25px !important;
  color: black;
}
.icon-check-table-global{
  font-size: 25px !important;
  color: #63e3b2 !important;
}
.txt-empty-global{
  /* color: rgb(85, 85, 85);; */
}
.content-divider-global{
  margin: 10px 0px !important;
  min-height: 1px;
  max-height: 1px;
  background-color: #E0E0E0 !important;
  min-width: 100%;
  max-width: 100%;
}

.td-divider-global{
  height: 20px !important;
}

.global-container-flex {
  display: flex;
  width: 100%;
  height: 100%;
}
.global-container-content {
  width: calc(100% - 260px);
}
.global-container-content-full-width {
  width: 100%;
}

@media (max-width: 900px) {
  .global-container-menu, .global-container-menu-close, .v-icon-menu-hidden {
    display: none !important;
  }
  .global-container-content {
    width: 100%;
  }
}

.global-container-menu {
  width: 300px;
  /* background-color: #151D27; */
  /* border-right: 1px solid #c7cfd6; */
  /* position: absolute;
  z-index: 9; */
  /* transition: width -0.9s; */
}

.global-container-menu-close {
  width: 100px;
  /* background-color: #151D27; */
  /* border-right: 1px solid #c7cfd6; */
  /* transition: width 0.3s; */
}

.global-container-router-view {
  padding: 10px;
  height: calc(100vh - 119px);
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #F2F2F4;
}
</style>